#pomodoro {
  background-color: rgba(162, 0, 213, 0.1);
  width: 70rem;
  height: 40rem;
  border-radius: 15px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  font-size: 24px;
}

#group {
  width: 45%;
  height: 30%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-top: 15px;
}
#session-label,
#break-label {
  align-items: center;
  width: 100%;
  flex-direction: column;
  text-align: center;
}
.control {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}
#session-increment,
#session-decrement,
#break-increment,
#break-decrement {
  width: 50px;
  margin: 2px;
}

#session-increment:hover,
#session-decrement:hover,
#break-increment:hover,
#break-decrement:hover {
  color: fuchsia;
}

#session-length,
#break-length {
  font-size: 42px;
  background-color: purple;
  width: 20%;
  text-align: center;
  border-radius: 4px;
}

span {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

#timer {
  width: 100%;
  height: 50%;
  flex-direction: column;
  /* background-color: rgba(162, 0, 213, 0.1); */
  padding: 10px;
  user-select: none;
  text-align: center;
}

#time-left {
  font-size: 82px;
}
.control_circles {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

#start_stop,
#reset {
  padding: 15px;
}

#start_stop:hover {
  color: greenyellow;
}

#reset:hover {
  color: red;
}
