@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;600&display=swap');

:root {
  --violet-light: rgba(162, 0, 213, 0.4);
  --violet-dark: #1a0022;
  --violet-scroll: rgba(162, 0, 213, 0.3);
  --height-mobile: 100vh;
  --height-desktop: 100vh;
}

html {
  font-size: 62.5%;
}

body {
  background: var(--violet-dark);
  margin: 0;
  font-family: 'Source Code Pro', monospace;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Typography */

.text--bold {
  /* font-weight: 600; */
  font-weight: bold;
}

.text--italic {
  font-style: italic;
}

.text--underline {
  text-decoration: underline;
}

h2 {
  min-height: 3.8rem;
}

/* Layout */

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10rem auto;
  height: 100vh;
  width: 100vw;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--violet-light);
  z-index: 10;
  background: var(--violet-dark);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
}

.main {
  color: #fff;
  padding: 0;
}

.main::-webkit-scrollbar {
  width: 1.2rem;
}

.main::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.main::-webkit-scrollbar-thumb {
  background-color: var(--violet-scroll);
  outline: 1px solid var(--violet-light);
}

/* Class from fullpage.js | default: middle |  mobile view */
.fp-tableCell {
  vertical-align: top;
}

@media screen and (min-width: 768px) {
  .container {
    display: grid;
    grid-template-columns: 34rem auto;
    grid-template-rows: 1fr;
  }
  .main {
    overflow-y: hidden; /* scroll */
    padding: 0;
    /* overflow-x: hidden; */
  }
  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid var(--violet-light);
    border-bottom: none;
    position: relative;
  }
}

/* Card */

.profile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.picture {
  width: 75px;
  height: 75px;
  overflow: hidden;
  border-radius: 50%;
  cursor: nwse-resize;
  user-select: none;
}

.picture > img {
  /* TODO: margin-top will change depending on which image [nasa, museum, silhouette] */
  width: 100%;
  margin-top: -1rem;
  transition: all 0.5s;
}

.picture > img:hover {
  width: 130%;
  margin-top: -4rem;
  margin-left: 0rem;
  filter: contrast(1.05);
  image-rendering: pixelated;
  /* mix-blend-mode: soft-light; */
}

.card__heading {
  color: #fff;
  font-size: 2rem;
  padding: 0;
  text-align: center;
  user-select: none;
  margin: 2rem 2rem;
}

.card__heading--light {
  font-weight: 200;
}

.card__heading--bold {
  font-weight: 400;
}

.social {
  display: none;
}

.profile .svg {
  width: 20px;
  height: 20px;
  margin: 0.25rem 0.01rem;
}

@media screen and (min-width: 768px) {
  .profile {
    display: block;
    margin-top: -4rem;
  }
  .picture {
    width: 175px;
    height: 175px;
    overflow: hidden;
    border-radius: 50%;
  }

  .picture > img {
    /* TODO: margin-top will change depending on which image [nasa, museum, silhouette] */
    width: 100%;
    margin-top: -3rem;
  }
  .card__heading {
    color: #fff;
    font-size: 2rem;
    padding: 0;
    text-align: center;
    user-select: none;
  }
  .social {
    display: flex;
    justify-content: center;
  }

  .profile .svg {
    width: 20px;
    height: 20px;
    padding: 0 0.25rem;
  }
}

/* Space Art */

.hero {
  display: flex;
  opacity: 1;
  overflow: hidden;
  position: relative;
}

#canvas {
  background: var(--violet-dark);
  height: 100vh;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -10;
}

.astronaut {
  align-self: flex-start;
  animation: wobble 5s infinite linear;
  bottom: 10rem;
  left: -5rem;
  position: absolute;
  width: 130%;
}

@keyframes wobble {
  0% {
    transform: rotate(0deg);
    transform-origin: center center;
  }
  50% {
    transform: rotate(0.5deg);
    transform-origin: center center;
  }
}

.rocket {
  position: absolute;
  bottom: 15rem;
  left: 7rem;
  font-size: 4rem;
  opacity: 0.2;
}

#tsparticles {
  display: none;
}

@media screen and (min-width: 768px) {
  .hero {
    display: flex;
    opacity: 1;
    overflow: hidden;
    position: relative;
    /* height: 100vh; */
  }

  #canvas {
    background: var(--violet-dark);
    height: 100vh;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -10;
  }

  #tsparticles {
    display: block;
    height: 50vh;
    position: absolute;
    top: 20%;
    width: 100%;
    z-index: -10;
    opacity: 1;
    /* border: 1px solid red; */
  }

  .astronaut {
    align-self: flex-start;
    animation: wobble 5s infinite linear;
    bottom: 0;
    left: -4rem;
    position: absolute;
    width: 110%;
  }

  @keyframes wobble {
    0% {
      transform: rotate(0deg);
      transform-origin: center center;
    }
    50% {
      transform: rotate(0.1deg);
      transform-origin: center center;
    }
  }

  .rocket {
    position: absolute;
    bottom: 15rem;
    left: 7rem;
    font-size: 4rem;
    opacity: 0.2;
  }
}
/* Arrows */

.arrow {
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform-origin: 50% 50%;
  transform: translate3d(-50%, -50%, 0);
}

.arrow-first {
  animation: arrow-movement 2s ease-in-out infinite;
}
.arrow-second {
  animation: arrow-movement 2s 1s ease-in-out infinite;
}

.arrow:before,
.arrow:after {
  background: #fff;
  content: '';
  display: block;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
}

.arrow:before {
  transform: rotate(45deg) translateX(-23%);
  transform-origin: top left;
}

.arrow:after {
  transform: rotate(-45deg) translateX(23%);
  transform-origin: top right;
}

@keyframes arrow-movement {
  0% {
    opacity: 0;
    top: 45%;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Arrows Modifications */

.arrows-container {
  display: flex;
  position: relative;
}

.arrows {
  padding: 1rem;
  box-shadow: 2px -2px white inset;
  transform: rotate(225deg);
  opacity: 0;
  position: absolute;
}

.arrow-one {
  animation: arrowMovement 2.5s ease-in-out infinite;
}

.arrow-two {
  animation: arrowMovement 2.5s 1s ease-in-out infinite;
}

.arrows-calc {
  /* border: 1px solid yellow; */
  opacity: 0.5;
  margin-top: -6rem;
}

.arrows-pomodoro {
  opacity: 0.5;
  margin: 1rem auto 0 auto;
  /* border: 1px solid yellow; */
}

@keyframes arrowMovement {
  0% {
    left: 0;
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    left: 30px;
    opacity: 0;
  }
}

/* Screens */
/* Skills */

.section__skills {
  height: 100vh;
  padding: 0 4rem;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  /* font-family: 'Inconsolata', monospace; */
  /* border: 1px solid yellow; */
}

.section__skills--middle {
  /* border: 1px solid red; */
  width: 100%;
  font-size: 1.5rem;
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  user-select: none;
}

.section__skills--middle h2 {
  margin: 0;
}

.text {
  /* border: 1px solid greenyellow; */
  padding-top: 2rem;
}

.text p {
  line-height: 1.4;
  opacity: 1;
}

.text--skills {
  min-height: 4.2rem;
}

.stack {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 0rem;
  row-gap: 1rem;
  justify-items: center;
  align-self: start;
}

.stack img {
  width: 50%;
  /* border: 1px solid green; */
}

.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

.wave img {
  width: 30px;
  height: 30px;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

.tippy-box {
  background-color: var(--violet-light);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
}

.tippy-arrow {
  color: var(--violet-light);
}

@media screen and (min-width: 768px) {
  .section__skills {
    height: 100vh;
  }
  .section__skills--middle {
    /* border: 1px solid red; */
    font-size: 2rem;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: none;
    align-items: center;
    user-select: none;
  }

  .section__skills--middle h2 {
    margin: 0;
  }

  .text {
    padding-top: 0;
  }

  .text p {
    line-height: 1.4;
    opacity: 1;
  }

  .text--skills {
    min-height: 5.6rem;
  }

  .stack {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 6rem 0 0 3rem;
    row-gap: 5rem;
    justify-items: center;
    cursor: crosshair;
    margin-top: -5rem;
    align-self: center;
  }
}

/* Calculator */

.section__calculator {
  /* border: 1px solid yellow; */
  display: flex;
  height: 100vh;
  justify-content: center;
}

.section__calculator--middle {
  padding: 0 4rem;
  font-size: 1.4rem;
}

.section__calculator--middle h2 {
  font-size: 2.3rem;
  font-weight: bold;
  text-align: center;
}

.calc {
  display: flex;
  align-items: center;
  opacity: 0.9;
  transform: scale(0.85);
  margin-top: -4rem;
}

@media screen and (min-width: 768px) {
  .section__calculator {
    align-items: center;
    /* border: 1px solid yellow; */
    display: flex;
    height: 100vh;
    justify-content: center;
    padding: 0 10rem;
  }

  .section__calculator--middle {
    align-items: center;
    column-gap: 6rem;
    display: grid;
    font-size: 1.9rem;
    grid-template-columns: 1fr 2fr;
    height: 100vh;
    width: 100vw;
    user-select: none;
    padding: 0;
  }

  .section__calculator--middle h2 {
    font-size: 2.8rem;
    text-align: left;
    /* border: 1px solid red; */
  }

  .calc {
    opacity: 0.9;
    transform: scale(0.98);
    margin-top: -1rem;
  }
}

/* Pomodoro */

.section__pomodoro {
  /* border: 1px solid red; */
  display: flex;
  height: 100vh;
  justify-content: center;
}

.section__pomodoro--middle {
  /* border: 1px solid yellow; */
  font-size: 1.5rem;
  /* display: grid; */
  /* grid-template-rows: 1fr 1fr; */
  padding: 2rem 4rem;
  /* align-items: center; */
}

.section__pomodoro--middle h2 {
  text-align: center;
}

.clock {
  /* display: block; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  transform: rotate(90deg) scale(0.7);
  /* margin: 0 auto 0 auto; */
  /* justify-self: center; */
  /* border: 1px solid greenyellow; */
  opacity: 1;
  user-select: none;
  align-self: start;
  margin-top: 6rem;
}

.arrows-pomodoro {
  display: flex;
  /* position: absolute; */
  /* left: 0; */
  /* margin-top: 4rem; */
  /* margin-right: 1rem; */
  /* align-items: baseline; */
  margin: 6rem 6rem 0 0;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .section__pomodoro {
    /* border: 1px solid yellow; */
    display: flex;
    height: 90vh;
    justify-content: center;
    padding: 4rem 10rem;
  }

  .section__pomodoro--middle {
    /* align-items: center; */
    /* border: 1px solid red; */
    display: grid;
    font-size: 1.9rem;
    /* grid-template-columns: 1fr; */
    grid-template-rows: 1fr 2fr;
    user-select: none;
    /* height: 100vh; */
  }

  .section__pomodoro--middle h2 {
    text-align: left;
  }

  .clock {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
    transform: none;

    margin: 0;
    justify-self: center;
    /* border: 1px solid greenyellow; */
    opacity: 1;
  }
}

/* Fullstack Intro */

.section__fullstack {
  /* border: 1px solid yellow; */
  display: flex;
  height: 85vh;
  justify-content: center;
}

.section__fullstack--middle {
  /* align-items: center; */
  /* border: 1px solid red; */
  display: grid;
  font-size: 1.5rem;
  grid-template-rows: 1fr;
  padding: 0rem 4rem;
  user-select: none;
}

.section__fullstack--middle h2 {
  /* margin: 0 -2rem; */
  font-size: 1.9rem;
}

.section__fullstack--text {
  /* margin-top: -14rem; */
}

.launch {
  background: url(./svg/launch.svg) no-repeat;
  background-size: cover;
  background-position: 50% 80%;
  background-size: 200%;
  height: 85vh;
  /* border: 1px solid purple; */
  z-index: -20;
  margin-top: -55rem;
  opacity: 0.7;
}

@media screen and (min-width: 768px) {
  .section__fullstack {
    /* border: 1px solid yellow; */
    display: flex;
    height: 90vh;
    justify-content: center;
    padding: 4rem 4rem;
  }

  .section__fullstack--middle {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    /* border: 1px solid red; */
    display: grid;
    font-size: 1.9rem;
    /* grid-template-rows: 1fr 2fr; */
    user-select: none;
  }
  .section__fullstack--middle h2 {
    /* margin: 0 -2rem; */
    font-size: 2.8rem;
  }

  .section__fullstack--text {
    margin-top: -14rem;
  }

  .launch {
    background: url(./svg/launch.svg) no-repeat;
    background-size: cover;
    background-position: 50% 60%;
    background-size: 250%;
    height: 100vh;
    z-index: 0;
    margin-top: 0rem;
    opacity: 1;
  }
}

/* HomeWorks */

.section__homeworks {
  /* border: 1px solid yellow; */
  display: flex;
  height: 100vh;
  justify-content: center;
}

.section__homeworks--middle {
  padding: 1rem 4rem;
  /* display: grid;
  grid-template-columns: 1fr; */
  font-size: 1.4rem;
  width: 100vw;
  user-select: none;
}

.section__homeworks--middle h2 {
  text-align: center;
  font-size: 2.5rem;
  margin: 0;
}

.homeworks__image img {
  width: 50%;
  border-radius: 10px;
}

.homeworks__iframe {
  border: 0;
  width: 96vw;
  height: 70vh;
  border-radius: 10px;
  background: rgba(162, 0, 213, 0.1);
}

.launch-container {
  display: flex;
  justify-content: center;
  margin: 0.4rem 0;
  /* border: 1px solid red; */
}

.launch-app {
  background-color: rgba(162, 0, 213, 0.2);
  border-radius: 10px;
  border: 1px solid var(--violet-light);
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  padding: 1rem 5rem;
  text-decoration: none;
  transition: background-color 0.5s;
}

.launch-app:hover {
  background-color: rgba(162, 0, 213, 0.5);
}

.launch-app-rocket {
  padding: 0 1.5rem;
  margin: 0 0.5rem;
  background: url(./images/rocket.png) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-size: 100%;
}

.homeworks-repos {
  display: flex;
  justify-content: center;
}

.homeworks-repos a {
  padding: 0 1rem;
}

@media screen and (min-width: 768px) {
  .section__homeworks {
    /* border: 1px solid yellow; */
    display: flex;
    height: 90vh;
    justify-content: center;
    padding: 4rem 4rem;
    font-family: 'Source Code Pro', monospace;
  }

  .section__homeworks--middle {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    font-size: 1.9rem;
    width: 100vw;
    user-select: none;
  }

  .section__homeworks--middle h2 {
    text-align: left;
    font-size: 3rem;
  }

  .homeworks__description {
    grid-column: 1 / 3;
    /* border: 1px solid red; */
  }

  .homeworks__image {
    grid-column: 3 / 6;
    /* border: 1px solid red; */
    text-align: center;
  }

  .homeworks__image img {
    width: 50%;
    border-radius: 10px;
  }

  .homeworks__iframe {
    border: 0;
    width: 400px;
    height: 650px;
    border-radius: 10px;
    background: rgba(162, 0, 213, 0.1);
  }

  .launch-container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin: 5rem 0;
  }

  .launch-app {
    background-color: rgba(162, 0, 213, 0.2);
    border-radius: 10px;
    border: 1px solid var(--violet-light);
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
    font-size: 2.5rem;
    font-weight: 600;
    padding: 1rem 13rem;
    text-decoration: none;
    transition: background-color 0.5s;
  }

  .launch-app:hover {
    background-color: rgba(162, 0, 213, 0.5);
  }

  .launch-app-rocket {
    padding: 0 1.5rem;
    margin: 0 0.5rem;
    background: url(./images/rocket.png) no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    background-size: 100%;
  }

  .homeworks-repos {
    display: flex;
    justify-content: center;
  }

  .homeworks-repos a {
    padding: 0 1rem;
  }
}
/* Mission Control */

.section__mission-control {
  /* border: 1px solid yellow; */
  display: flex;
  height: 100vh;
  justify-content: center;
  font-family: 'Source Code Pro', monospace;
  /* background: url(./svg/launchpad.svg) no-repeat;
  background-size: cover;
  background-position: 55% -10%;
  background-size: 111%; */
}

.section__mission-control--middle {
  display: grid;
  grid-template-rows: 20vh 1fr;
  font-size: 1.9rem;
  /* width: 100vw; */
  user-select: none;
  /* align-items: center; */
  /* column-gap: 2rem; */
  /* border: 1px solid red; */
  padding: 2rem 4rem;
}

.section__mission-control--middle h2 {
  min-height: 5rem;
  font-size: 2rem;
  /* border: 1px solid red; */
}

.mission-control {
  background: url(./svg/astromoon.svg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-size: 50%;
  height: 20vh;
}

.mission-control--end {
  /* margin-top: -1rem; */
}

.mission-control--text a {
  color: #fff;
  font-weight: 700;
  text-decoration: none;
}

.backToTop {
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .section__mission-control {
    /* border: 1px solid yellow; */
    display: flex;
    height: 100vh;
    justify-content: center;
    padding: 4rem 0rem;
    font-family: 'Source Code Pro', monospace;
    /* background: url(./svg/launchpad.svg) no-repeat;
    background-size: cover;
    background-position: 55% -10%;
    background-size: 111%; */
  }

  .section__mission-control--middle {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: none;
    font-size: 1.9rem;
    width: 100vw;
    user-select: none;
    align-items: center;
    column-gap: 2rem;
    /* border: 1px solid red; */
  }

  .section__mission-control--middle h2 {
    min-height: 5rem;
    font-size: 2.8rem;
    /* border: 1px solid greenyellow; */
  }

  .mission-control {
    background: url(./svg/astromoon.svg) no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    background-size: 100%;
    height: 100vh;
  }

  .mission-control--text a {
    color: #fff;
    font-weight: 700;
    text-decoration: none;
  }
}
